import { I18n } from "i18n-js";

const i18n = new I18n({
    en: {
        message: `We collect cookies. It is secure and confidential. If you don't mind, just keep using the site. 
            <a href="https://lms.smartexpert.net/documents/cookies_policy_se.pdf">More details</a>`,
        button: "Okey",
    },
    ru: {
        message: `Мы собираем файлы куки. Это безопасно и конфиденциально.<br>
              Если вы не против, просто продолжайте пользоваться сайтом. 
              <a href="https://lms.smartexpert.net/documents/cookies_policy_se.pdf">Подробнее</a>`,
        button: "Хорошо",
    },
    uk: {
        message: `Ми збираємо файли cookie. Це безпечно і конфіденційно. Якщо не заперечуєте, просто продовжуйте користуватися сайтом.
            <a href="https://lms.smartexpert.net/documents/cookies_policy_se.pdf">Докладніше</a>`,
        button: "Добре",
    },
});

i18n.locale =
    (!!window.currentUser ? window.currentUser.locale : window.locale) || window.defaultLocale;

class CookieWidget {
    template = `
        <div class="Widget">
          <div class="Widget__Widget-cookie">
            <div class="Widget__Widget-cookie_text">
              ${i18n.t("message")}
            </div>
            <div class="Widget__Widget-cookie_button-wrapper"> 
              <button class="Widget__Widget-cookie_button" id="widget-ok-button">
                    ${i18n.t("button")}
                </button>
            </div>
          </div>
        </div>
        `;
    show() {
        document.body.insertAdjacentHTML("afterend", this.template);
        document.querySelector(".Widget #widget-ok-button").addEventListener("click", this.hide);
    }
    hide() {
        localStorage.acceptCookieSaving = true;
        document.querySelector(".Widget").remove();
        document.querySelector(".Widget #widget-ok-button").removeEventListener("click", this.hide);
    }
}

const widget = new CookieWidget();
if (!localStorage.getItem("acceptCookieSaving")) {
    widget.show();
}
